import styles from "components/header/WishlistButton.module.scss";
import isEmpty from "lodash/isEmpty";
import useWishlist from "grandus-lib/hooks/useWishlist";
import Link from "next/link";

const WishlistButton = () => {
  const { wishlist, isLoading } = useWishlist();
  return (
    <Link href="/wishlist" as={`/wishlist`}>
      <a className={styles.wishlist}>
        {!isEmpty(wishlist?.productIds) ? <div className={styles.badge}>{wishlist?.productIds.length}</div> : ""}
        <svg
          width="40"
          height="30"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.98859 3.19923C6.04068 -0.243231 1.58888 0.659012 1.05622 3.81106C0.4769 7.25352 4.48547 10.9361 7.98859 13C11.4917 10.9361 15.6247 7.26901 14.921 3.81106C14.2794 0.678373 9.93649 -0.243231 7.98859 3.19923Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Obľúbené
      </a>
    </Link>
  );
};

export default WishlistButton;
