export const COOKIE_ACCCEPT_ALCOHOL = "COOKIE_ACCCEPT_ALCOHOL";

export const HEADER_MENU_CATGEORY = "TOPBAR_MENU_ITEM";

export const CATEGORY_MICE = "MICE";
export const CATEGORY_SRANDON = "SRANDON";

export const LISTING_VIEW_TYPE_GRID = "GRID";
export const LISTING_VIEW_TYPE_LIST = "LIST";
export const LISTING_VIEW_TYPE_MAP = "MAP";

export const LISTING_VIEW_TYPE_PINBOARD = "GRID";
export const LISTING_VIEW_TYPE_MONTH = "MONTH";
export const LISTING_VIEW_TYPE_WEEK = "WEEK";

export const EVENT_DATE_PARAMETERS=["datum-konania","date-of-the-event","datum-der-veranstaltung"]

export const EVENT_DATE_PARAMETER_LOCALES= {sk: "datum-konania", en:"date-of-the-event", de:"datum-der-veranstaltung"}

export const LISTING_VIEW_TYPE_LABEL_GRID = "Zobraziť ako dlaždice";
export const LISTING_VIEW_TYPE_LABEL_LIST = "Zobraziť ako zoznam";
export const LISTING_VIEW_TYPE_LABEL_MAP = "Zobraziť ako mapu";

export const LISTING_VIEW_TYPES = [
  { type: LISTING_VIEW_TYPE_GRID, label: LISTING_VIEW_TYPE_LABEL_GRID },
  { type: LISTING_VIEW_TYPE_LIST, label: LISTING_VIEW_TYPE_LABEL_LIST },
  { type: LISTING_VIEW_TYPE_MAP, label: LISTING_VIEW_TYPE_LABEL_MAP },
];

export const PARAMETER_ALCOHOL = "CONTAINS_ALCOHOL";
export const PARAMETER_MAP_COORDINATES = "MAP_COORDINATES";
export const PARAMETER_EVENT_START = "EVENT_START";
export const PARAMETER_EVENT_END = "EVENT_END";
export const PARAMETER_DATE = "EVENT_DATE";
export const PARAMETER_EMBEDED_CONTENT = "EMBEDED_CONTENT";

export const CONTACT_PAGE_HASH = 'CONTACT_PAGE';

export const PARAMETER_BLACKLIST = [
  PARAMETER_ALCOHOL,
  PARAMETER_MAP_COORDINATES,
  PARAMETER_EVENT_START,
  PARAMETER_EVENT_END,
  PARAMETER_EMBEDED_CONTENT,
  PARAMETER_DATE
];

export const ADDITIONAL_INFO_CUSTOM = 0;
export const ADDITIONAL_INFO_PERSON = 2;

export const KIND_COLORS_BY_HASH = {
  PAMIATKY: "#FBD136",
  SLUZBY: "#3CA3DB",
  PRIRODA: "#B3D33C",
  PREHLIADKY: "#AC5787",
  PODUJATIA: "#F15F23",
};

export const KIND_IMAGES_BY_HASH = {
  DEFAULT: {
    equal: "/files/attachment/2020-09/115/cee36a/default-11.jpg",
    horizontal: "/files/attachment/2020-09/118/91e122/default-21.jpg",
    vertical: "/files/attachment/2020-09/121/f9ffa2/default-12.jpg",
  },
  PAMIATKY: {
    equal: "/files/attachment/2020-09/124/ef54ad/pamiatky-11.jpg",
    horizontal: "/files/attachment/2020-09/127/63e0f6/pamiatky-21.jpg",
    vertical: "/files/attachment/2020-09/130/8c4351/pamiatky-12.jpg",
  },
  SLUZBY: {
    equal: "/files/attachment/2020-09/133/4959cb/sluzby-11.jpg",
    horizontal: "/files/attachment/2020-09/139/6663ba/sluzby-21.jpg",
    vertical: "/files/attachment/2020-09/136/101c86/sluzby-12.jpg",
  },
  PRIRODA: {
    equal: "/files/attachment/2020-09/142/5acff5/priroda-11.jpg",
    horizontal: "/files/attachment/2020-09/145/8487c8/priroda-21.jpg",
    vertical: "/files/attachment/2020-09/148/0393a8/priroda-12.jpg",
  },
  PREHLIADKY: {
    equal: "/files/attachment/2020-09/157/7b81e4/prehliadky-11.jpg",
    horizontal: "/files/attachment/2020-09/151/9363ad/prehliadky-21.jpg",
    vertical: "/files/attachment/2020-09/154/213f02/prehliadky-12.jpg",
  },
  PODUJATIA: {
    equal: "/files/attachment/2020-09/166/502e72/podujatia-11.jpg",
    horizontal: "/files/attachment/2020-09/160/45978e/podujatia-21.jpg",
    vertical: "/files/attachment/2020-09/163/fa4acb/podujatia-12.jpg",
  },
};

export const GOOGLE_MAPS_API_KEY = "AIzaSyDfDxth3ppfhTjxRadlna3z-dIejB_RL24";
export const GOOGLE_MAPS_URL =
  "https://maps.googleapis.com/maps/api/js?key=" + GOOGLE_MAPS_API_KEY;
export const GOOGLE_MAPS_STYLE = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#e9e9e9",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#dedede",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#333333",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#f2f2f2",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
];

export const ARTICLE_PARAM_ID = 24241
