import {CaretDownOutlined} from "@ant-design/icons";
import upperCase from "lodash/upperCase";
import styles from "./Menu.module.scss"
import map from "lodash/map";
import {useRouter} from "next/router";
import dayjs from "dayjs";
import Cookies from "js-cookie";

const LanguageSwitch = () => {
  const router = useRouter();
  const {locales, locale: activeLocale, defaultLocale} = router;

  const handleLocaleChange = async (e, locale) => {
    e.preventDefault();
    if (locale !== activeLocale) {
      await Cookies.set('NEXT_LOCALE', locale);
      dayjs.locale(locale);

      if (locale === defaultLocale) {
        window.location.href = `/`
      } else {
        window.location.href = `/${locale}`
      }
    }
  };

  return (
    <>
      <li className={`${styles.menuItem} ${styles.languageSwitch}`}>
        <a href={"#"}>
          {upperCase(activeLocale)} <CaretDownOutlined/>
        </a>

        <ul className={styles.submenu}>
          {map(locales, (language) => {
            if (language === activeLocale) {
              return "";
            }
            return (
              <li key={language}>
                <a href={"#"} onClick={(e) => handleLocaleChange(e, language)}>
                  {upperCase(language)}
                </a>
              </li>
            );
          })}
        </ul>
      </li>
    </>
  )
}

export default LanguageSwitch;
